// 
// _dropdown.scss
// 

.dropdown-menu {
    box-shadow: $box-shadow;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    padding: 0.25rem;
    border: 1px solid var(--#{$prefix}border-color);

    &.show {
        top: 100% !important;
    }
}

.dropdown-menu-end[style] {
    left: auto !important;
    right: 0 !important;
}

.dropdown-menu[data-popper-placement^=right],
.dropdown-menu[data-popper-placement^=top],
.dropdown-menu[data-popper-placement^=left] {
    top: auto !important;
    animation: none !important;
}

@keyframes DropDownSlide {
    100% {
        transform: translateY(0)
    }

    0% {
        transform: translateY(10px)
    }
}

@media (min-width: 600px) {
    .dropdown-menu-lg {
        width: 320px;
    }

    .dropdown-menu-md {
        width: 240px;
    }
}

// .dropdown-divider {
//     border-top-color: $border-color;
// }

// Dropdown Mega Menu

.dropdown-mega {
    position: static !important;
}

.dropdown-megamenu[style] {
    padding: 20px;
    left: 20px !important;
    right: 20px !important;
}

// Dropdown size

.dropdown-mega-menu-xl {
    width: 40rem;
}

.dropdown-mega-menu-lg {
    width: 26rem;
}

// 
// Dropdown menu item color
// 

@each $color,
$value in $theme-colors {
    .dropdownmenu-#{$color} {
        .dropdown-item {

            &:focus,
            &:hover,
            &.active {
                background-color: rgba(($value), 0.07) !important;
                color: $value;
            }
        }
    }
}


.btn-primary{
    &.dropdown-toggle-split{
        background-color: rgba(var(--bs-primary-rgb), 95%);
    }
}