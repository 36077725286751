//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-medium;
  }

  .table-light {
    color: var(--#{$prefix}secondary-color);
    border-color: $table-border-color;
    th,
    td {
      background-color: var(--#{$prefix}tertiary-bg) !important;
    }
  }
}

.table-bordered {
  border: $table-border-width solid var(--#{$prefix}border-color);

  th,
  td {
    border: $table-border-width solid var(--#{$prefix}border-color);
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}

